.comment-view-block {
  padding-top: 20px;
  border-top: 2px solid #dfdfdf;
  margin-bottom: 20px;
}
.block-with-saved-comment {
  background-color: rgba(0, 188, 19, 0.3);
  padding: 16px;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #878787;
  font-size: 14px;
  margin-bottom: 20px;

  .icon-with-comment {
    padding-right: 10px;
    display: flex;
    gap: 10px;
  }
}
.block-with-comment {
  background-color: rgba(0, 188, 19, 0.3);
  padding: 16px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #878787;
  font-size: 14px;
  margin-bottom: 20px;
  gap: 10px;

  .icon-with-comment {
    padding-right: 10px;
    display: flex;
    gap: 10px;
  }
}

.block-with-comment-green {
  width: 95%;
  background-color: rgba(0, 188, 19, 0.3);
  padding: 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: #878787;
  font-size: 14px;
  margin-bottom: 20px;
  gap: 10px;

  .icon-with-comment {
    padding-right: 10px;
    display: flex;
    gap: 10px;
  }
}
.block-with-comment-green-imsma {
  width: 98%;
  background-color: rgba(0, 188, 19, 0.3);
  padding: 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: #878787;
  font-size: 14px;
  margin-bottom: 20px;
  gap: 10px;
  .icon-with-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .icon-with-comment {
    padding-right: 10px;
    display: flex;
    gap: 10px;
  }
}
.block-with-comment-gray-imsma {
  width: 98%;
  background-color: #e8e8e8;
  padding: 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: #878787;
  font-size: 14px;
  margin-bottom: 20px;
  gap: 10px;
  .icon-with-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .icon-with-comment {
    padding-right: 10px;
    display: flex;
    gap: 10px;
  }
}

.saved-comment {
  max-width: 70vw;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.informator-block-comment {
  color: #878787;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.comment {
  max-width: 70vw;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.block-with-comment-gray {
  width: 95%;
  background-color: #e8e8e8;
  padding: 16px;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #878787;
  font-size: 14px;
  margin-bottom: 20px;
  .icon {
    padding-right: 10px;
    display: flex;
  }
}

.input-with-button {
  display: grid;
  grid-template-columns: 95% 0px;
  align-items: center;
}

.input-view {
  width: 95%;
}

.btn-inside-without-gap {
  padding-left: 30px;
  padding-right: 30px;
}

.info_block_header {
  background-color: #00b1b7;
  border-radius: 20px;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 16px;

  &_left {
    color: #f0f0f0;
    font-size: 21px;
    font-weight: 600;
  }

  &_right {
    color: #f0f0f0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-top: 4px;
  }
  &_line {
    width: 90%;
    margin-left: 18px;
    margin-right: 18px;
    border-bottom: 2px solid #dfdfdf;
  }
}

.block-with-checkbox-icon {
  display: flex;
  gap: 5px;
  align-items: center;
}
.photos-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  .photo-block-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    .photo-block-header-text {
      margin: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.download-all-photos-button {
  position: absolute;
  right: 0;
}
.download-all-attachments-button {
  position: absolute;
  right: 0;
}
.attachments-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  .attachments-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .attachments-header-text {
      margin: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
