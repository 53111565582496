.loader {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px) brightness(0.5);
    z-index: 1111;
}

.small-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px) brightness(0.5);
    z-index: 4;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    svg {
        background-color: transparent !important;
    }
}

.daily-map-loader {
    .small-loader {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }
}
