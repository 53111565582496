.color-picker-with-input {
  display: flex;
  gap: 6px;
  .color-picker {
    width: 40px;
    height: 38px;
    background-color: white;
    color: #000;
    border-radius: 5px;
    margin: 0px;
    padding: 0px;
  }
  .color-picker-input {
    padding: 5px;
    border: 1px solid #8f8f8f;
    width: 100px;
    margin-right: 10px;
    border-radius: 5px;
  }
  .css-1j0mc3w-ColorPicker {
    display: none;
  }
}
