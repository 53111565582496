.dashboard-main {
    display: flex;
    min-height: 100vh;
}

main {
    display: flex;
    justify-content: flex-end;
    aside {
        width: 200px;
        height: 100vh;
        padding: 32px;
        position: fixed;
        z-index: 1113;
        background: #1e1e1e;
        left: 0;
    }
    section {
        width: calc(100% - 328px);
        padding: 32px;
        background-color: #f4f5f7;
        position: relative;
        min-height: calc(100vh - 64px);
        &.no-padding {
            width: calc(100% - 264px);
            padding: 0;
        }
    }
}

nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    gap: 40px;
    ul {
        max-height: calc(100vh - 306px);
        overflow: auto;
    }
}

ul {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    padding: 0;
    margin: 0;

    a {
        width: 100%;
    }

    li {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        gap: 12px;
        color: #f0f0f0;
        img {
            filter: brightness(2);
        }
        &:hover {
            cursor: pointer;
        }
    }
}

.nav-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #00b1b7;
    font-weight: 600;
    position: absolute;
    bottom: 100px;
    width: 100%;
}

.logout {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    img {
        filter: brightness(1.5);
    }
    &:hover {
        cursor: pointer;
        color: #005d67;
    }
}

.user-nav {
    position: absolute;
    background-color: #1e1e1e;
    /* padding: 8px; */
    right: 40px;
    box-shadow: 0px 0px 4px 0px;
    width: calc(100% - 60px);
    border-radius: 8px;
}

.horizontal-nav {
    background-color: #1e1e1e;
    height: 60px;
    padding: 0 20px;
    .logo {
        width: 115px;
        height: 60px;
    }
}
