.map-container {
    height: 400px;
    &.full {
        position: relative;
        height: 100vh;
    }
    &.full-public {
        position: relative;
        height: calc(100vh - 60px);
    }
    &.width-500 {
        width: 500px;
    }
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-right {
    display: none;
}

.map-wrapper {
    position: relative;
    .mapbox-control {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 0 0 8px;
        box-shadow: 0px 20px 25px 0px rgba(76, 103, 100, 0.1);
        padding: 10px;
    }
}

.mapbox-popup {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    width: 250px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0 8px 8px 0;
    box-shadow: 0px 20px 25px 0px rgba(76, 103, 100, 0.1);
    padding: 10px;
    &.full {
        width: 500px;
        overflow: auto;
    }
}

.daily-map {
    position: relative;
    overflow: hidden;

    .legend-container {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        transition: transform 0.2s ease-in-out;

        &.open {
            transform: translateX(0);
        }

        &.closed {
            transform: translateX(110%);
        }

        .close-legend {
            position: absolute;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            cursor: pointer;
            color: #00b1b8;
            &:hover {
                transition: transform 0.4s ease-in-out;
                width: 36px;
                height: 36px;
            }
        }
    }

    .open-legend {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        cursor: pointer;
        color: #00b1b8;

        &:hover {
            transition: transform 0.4s ease-in-out;
            width: 36px;
            height: 36px;
        }
    }

    .legend {
        .title {
            color: #555555;
            font-size: 16px;
            font-weight: 500;
        }

        background-color: #cfd8e580;
        border-radius: 0px 23px 23px 0px;
        padding: 20px;
        height: calc(100% - 40px);
        width: 250px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: auto;
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
        }
    }
}
