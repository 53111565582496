.two-moved-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px;
  .column {
    width: 40%;
    padding: 10px;
    .header {
      color: #868686;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 8px;
      margin-top: 8px;
    }
    .content {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      border: 1px solid rgba(134, 134, 134, 1);
      border-radius: 8px;
      padding: 10px;
      height: 240px;
      overflow: auto;
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
      }
    }
    .selected-item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      color: #868686;
      margin: 4px;
      .delete-button {
        cursor: pointer;
        color: #868686;
        font-size: 14px;
        font-weight: 400;
        &:hover {
          color: #D26E6E;
        }
      }
    }
  }
  .move-button {
    margin-top: 20px;
  }
}
