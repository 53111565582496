.users {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 20px;

  &-title {
    font-size: 26px;
    color: #868686;
    font-weight: 600;
  }

  &-header {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  &-line {
    height: 2px;
    background-color: #f4f5f7;
    width: 100%;
    border: none;
  }
  &-row {
    height: 50px;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.react-confirm-alert-overlay {
  opacity: 0.95;
  background: #171f1f7d;
  backdrop-filter: blur(5px);
}

.confirm-user-popup-wrapper {
  .MuiPaper-root {
    overflow: visible;
  }
}

.info-user-popup {
  .body-section {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .first-section {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .second-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      align-items: flex-start;
      min-width: 200px;
    }
  }
}

.avatar-view {
  svg {
    width: 80px;
    height: 80px;
  }
  img {
    width: 130px;
    height: 130px;
    border-radius: 20px;
    object-fit: cover;
  }
}
