.grey-wrapper {
  background: #f4f5f7;
  padding: 20px 10px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.beneficiaries {
  .double-text {
    min-width: 110px;
  }
}

.total-beneficiaries-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  .total-beneficiaries {
    border-left: 2px solid #d7dadb;
    height: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;

    display: flex;
    flex-direction: column;
    width: fit-content;
    div,
    span {
      &:first-child {
        color: #1e1e1e;
        font-weight: 600;
        font-size: 14px;
      }
      &:last-child {
        color: #878787;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.handouts {
  display: flex;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #e0e0e0;
  padding: 10px 0;
  gap: 30px;
  align-items: flex-end;

  .handout-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 18%;
    padding-bottom: 5px;
    overflow-wrap: anywhere;

    .input-wrapper {
      width: auto;
    }
  }
}

.contact-person-block {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 0;
}

.template-version {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 0px 10px;
  font-size: 14px;
  .date {
    color: #1e1e1e;
    font-weight: 600;
  }
}

.download-all-photos {
  width: 185px;
}

.eore-table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 20px;
  a {
    color: inherit;
  }
  & .text-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}

.download-all-attachments {
  margin-right: 15px;
}

.beneficiaries-view,
.contact-person-view {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  padding: 8px;
}
.flex-column-with-save-button {
  display: flex;
  gap: 30px;
}
