.weekly-reports-popup {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.weekly-report-dialog .checkbox-wrapper {
  display: inline-flex !important;
  align-items: unset !important;
  margin: unset !important;
}
.scrollable-tbody {
  display: block;
  max-height: 280px;
  overflow-y: auto;
  padding-bottom: 10px;
}

.scrollable-tbody tr {
  width: 100%;
  table-layout: fixed;
}

.buttons-group-for-popup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: auto;
}
