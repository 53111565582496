.donors {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 20px;
    .donors-link {
        color: currentColor;
    }
}

.donors-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    border-top: 2px solid #e8e9ed;
    padding: 16px 0;
    margin: 16px 0;
}

.donor-main-info,
.donor-popup-info {
    background: #ffffff;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 143px;
    transform: translate(0px, 0px);
    border-radius: 0px 20px 20px 0px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #00b1b7;
        font-size: 16px;
        font-weight: 600;
        padding: 8px 0;
        border-bottom: 2px solid #00b1b737;
    }

    .body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        > div {
            padding: 8px 0;
            font-weight: 600;
        }
    }
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
        padding: 8px 0;
        border-top: 2px solid #00b1b737;
    }
}

.donor-popup-info {
    border-radius: 20px 0px 0px 20px;
    top: unset;
    left: unset;
    right: 0;
    bottom: 40px;
    width: 360px;
}
