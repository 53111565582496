.completed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 20px;
  height: 82vh;
  margin-top: 10px;
  .completed-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 32px;

    .input-with-checkbox {
      display: flex;
      width: 280px;
      gap: 24px;
    }
  }
  .completed-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    border-top: 2px solid #e8e9ed;
    padding: 16px 0;
    margin: 16px 0;
  }
}
