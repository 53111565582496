.confirm-popup {
  background: #ffffff;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
    border-bottom: 2px solid #00b1b743;
  }

  .title {
    color: #00b1b7;
    font-size: 16px;
    font-weight: 600;
  }

  .message {
    color: #d26e6e;
    font-size: 16px;
    font-weight: 600;
  }

  .closeBtn {
    path {
      fill: #d26e6e;
    }
    cursor: pointer;
  }
}

.confirm-user-popup {
  background: #ffffff;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  p {
    margin: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
    border-bottom: 2px solid #00b1b7b7;
  }
  .body-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .first-section,
    .second-section {
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
    }
    .column {
      display: flex;
      flex-direction: column;
      width: auto;
      align-items: center;
    }
    .user-fields {
      display: flex;
      flex-direction: column;
      gap: 24px;
      min-width: 200px;
    }
    &-header {
      color: #868686;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .profile {
      width: 260px;
      height: 140px;
      padding: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 20px;
      border: 2px dashed #00b1b7;
      position: relative;

      & svg {
        width: 40px;
        height: 40px;
        background-color: #f4f5f7;
        border-radius: 20px;
        padding: 20px;
      }

      .small-loader {
        backdrop-filter: initial;
      }
    }

    .profile-with-avatar {
      width: 300px;
      min-height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 20px;
      .change-image-with-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        margin-top: 10px;
      }
      .section-avatar {
        width: 200px;
        height: 140px;
        display: inline-block;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }
    }
  }

  .profile-without-avatar {
    width: 200px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40px;

    align-items: center;
    border-radius: 20px;
    border: 2px dashed #00b1b7;

    & svg {
      width: 60px;
      height: 60px;
      background-color: #f4f5f7;
      border-radius: 20px;
      padding: 20px;
      color: #868686;
    }
  }

  .middle-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .middle-section-userInfo {
    display: flex;
    justify-content: space-between;
  }

  .column {
    flex: 1;
    margin-right: 20px;

    .userInfo-title {
      color: #1e1e1e;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 6px;
    }

    .userInfo-subTitle {
      color: #868686;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-header {
      color: #868686;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 16px;
      margin-top: 8px;
    }

    .allCheckbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .checkbox-with-name {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        color: #868686;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
      .item-with-checkbox {
        padding-top: 9px;
      }
      .item-with-default-checkbox {
        padding-top: 5px;
        padding-left: 4px;
      }
    }
  }

  .title {
    color: #00b1b7;
    font-size: 16px;
    font-weight: 600;
  }

  .message {
    color: #d26e6e;
    font-size: 16px;
    font-weight: 600;
  }
}

.confirm-token-popup {
  background: #ffffff;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  // overflow: auto;
  p {
    margin: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
    border-bottom: 2px solid #00b1b743;
  }
  .title {
    color: #00b1b7;
    font-size: 16px;
    font-weight: 600;
  }
  .message {
    color: #d26e6e;
    font-size: 16px;
    font-weight: 600;
  }
  .closeBtn {
    path {
      fill: #d26e6e;
    }
    cursor: pointer;
  }

  .middle-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    .input-for-materials {
      display: flex;
      gap: 10px;
      align-items: center;
      width: 100%;
    }
    .css-b62m3t-container {
      width: 100%;
    }
  }
}

.react-confirm-alert-overlay {
  opacity: 0.95;
  background: #171f1f7d;
  backdrop-filter: blur(5px);
}
.closeBtn {
  background-image: url("../images/icons/close_24px.svg");
  cursor: pointer;
  width: 24px;
  height: 24px;
  &:hover {
    filter: brightness(1.5);
  }
}

.filter-dialog {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    overflow-y: visible !important;
  }
}

.role-dialog {
  .MuiDialog-paperScrollPaper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: visible !important;
  }
}

.role-popup {
  display: flex;
  justify-content: center;
  background: #ffffff;
  width: 1000px;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  p {
    margin: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
    border-bottom: 2px solid #00b1b7b7;
  }

  .title {
    color: #00b1b7;
    font-size: 16px;
    font-weight: 600;
  }

  .closeBtn {
    path {
      fill: #d26e6e;
    }
    cursor: pointer;
  }
  .role-inputs {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
  }

  .access-all-list-title {
    flex: 9;
    margin-bottom: 4px;
    color: #868686;
    margin-left: 20px;
  }

  .access-user-list-title {
    flex: 7;
    margin-bottom: 4px;
    color: #868686;
  }

  .user-access-list {
    width: 40%;
    overflow-y: auto;
    border: 1px solid rgba(134, 134, 134, 1);
    border-radius: 8px;
    padding-left: 10px;
    margin-left: 20px;
  }
  .user-access-list::-webkit-scrollbar-thumb {
    border-radius: 20px;
  }

  .all-access-list {
    width: 40%;
    overflow-y: auto;
    border: 1px solid rgba(134, 134, 134, 1);
    border-radius: 8px;
    padding-top: 8px;
    padding-left: 16px;
    margin-right: 20px;
    color: #868686;
  }
  .all-access-list::-webkit-scrollbar-thumb {
    border-radius: 20px;
  }
}
