.lists-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 16px 0;
  .title {
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: #868686;
    .datePicker {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }
  // .custom-datepicker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  //   height: 40px;
  // }
}
