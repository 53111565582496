.eore-materials-container {
  max-height: 73vh;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 16px 16px 16px 16px;
  overflow-y: auto;
  overflow-x: hidden;

  .eore-materials-accordion {
    width: 99%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    color: #868686;
    margin-left: 6px;
    padding-bottom: 12px;
    padding-top: 12px;
  }
}
.arrow-icon {
  transition: transform 0.3s ease;
  :hover {
    fill: #00b1b7;
    cursor: pointer;
  }
}

.arrow-icon.rotated {
  transform: rotate(180deg);
}
