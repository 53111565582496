.dashboardCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 420px;
  height: 400px;
  border-radius: 8px;
  padding: 24px;
  gap: 32px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 25px 0px rgba(76, 103, 100, 0.1);

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 16px;
    padding-top: 8px;
    border-bottom: 2px solid rgba(240, 240, 240, 1);
    .teamLead {
      border-radius: 50px;
      padding: 16px 24px;
      background: rgba(226, 0, 14, 0.1);
      color: #868686;
      margin-right: 10px;
      font-size: 16px;
      font-weight: 600;
    }
    .user-name {
      color: rgba(0, 177, 183, 1);
      font-weight: 600;
      font-size: 18px;
    }
    .user-name.truncated {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .user-name.truncated:hover {
      white-space: normal;
      overflow: visible;
      text-overflow: unset;
      background-color: #fff;
      z-index: 1;
    }
  }

  .middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    .leftSection {
      span {
        font-size: 16px;
        color: rgba(134, 134, 134, 1);
        font-weight: 500;
        margin-left: 4px;
      }
      p {
        margin-top: 12px;
        font-size: 18px;
        color: rgba(0, 177, 183, 1);
        font-weight: 600;
      }

      .created {
        .circleWithSpan {
          display: flex;
          align-items: center;
          gap: 3px;

          .circle {
            box-sizing: border-box;
            width: 18px;
            height: 18px;
            border-radius: 16px;
            background: #868686;
            border: 1px solid #dfdfdf;
            box-shadow: 0px 20px 25px rgba(76, 103, 100, 0.1);
          }
        }
      }

      .submitted {
        .circleWithSpan {
          display: flex;
          align-items: center;
          gap: 3px;

          .circle {
            box-sizing: border-box;
            width: 18px;
            height: 18px;
            border-radius: 16px;
            background: rgba(0, 188, 19, 1);
            border: 1px solid #dfdfdf;
            box-shadow: 0px 20px 25px rgba(76, 103, 100, 0.1);
          }
        }
      }

      .rejected {
        .circleWithSpan {
          display: flex;
          align-items: center;
          gap: 3px;

          .circle {
            box-sizing: border-box;
            width: 18px;
            height: 18px;
            border-radius: 16px;
            background: rgba(210, 110, 110, 1);
            border: 1px solid #dfdfdf;
            box-shadow: 0px 20px 25px rgba(76, 103, 100, 0.1);
          }
        }
      }
    }
    .rightSection {
    }
  }
  .footer {
    margin-bottom: 28px;
  }
}

.dashboard-tabs {
  display: flex;
  margin-bottom: 10px;
}

.allCards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.date-with-button {
  display: flex;
  align-items: center;
}
.dashboard-footer {
  margin-top: 40px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
