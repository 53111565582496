.page-title {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 8px 0;
    font-size: 26px;
    font-family: "Montserrat-Medium";
    color: #868686;
    .blue-text {
        color: #00b1b7;
    }
}

.white-bg {
    background-color: #fff;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.grid-columns-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    white-space: nowrap;
}

a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
        color: #00b1b7;
    }
    &:focus-visible {
        outline: 0;
    }
}

.blue-link {
    font-size: 14px;
    color: #00b1b7;
}

.flex-group {
    display: flex;
    gap: 8px;
    align-items: center;
}

.flex-column-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: left;
}
.flex-column-group-with-gap {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.no-wrap {
    white-space: nowrap;
}

.flex-left {
    display: flex;
    justify-content: flex-start !important;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
}

.align-center {
    align-items: center;
}

.align-left {
    align-items: flex-start;
}

.align-right {
    align-items: flex-end;
}

.justify-center {
    justify-content: center;
}

// temp code

.table-wrapper {
    table {
        width: 100%;
        text-align: center;
        th,
        td {
            text-align: center;
        }
        #files-info {
            td {
                border-radius: 40px 0px 0px 40px;
                background: #f4f5f7;
            }
        }
    }
}

.text {
    font-family: "Montserrat-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #878787;
    &.error {
        font-weight: 600;
        color: #d26e6e;
    }
}

.star {
    color: #d26e6e !important;
}

.text-hover {
    display: flex;
    align-items: center;
    gap: 5px;
    &:hover {
        cursor: pointer;
        color: #00b1b7;
        svg {
            path {
                stroke: #00b1b7;
            }
        }
        img {
            filter: brightness(1.1);
        }
    }
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-49 {
    width: 49%;
}

.double-text {
    display: flex;
    flex-direction: column;
    width: fit-content;
    div,
    span {
        &:last-child {
            color: #878787;
            font-weight: 400;
            font-size: 14px;
        }
        &:first-child {
            color: #1e1e1e;
            font-weight: 600;
            font-size: 14px;
        }
    }
    &.error {
        span,
        div {
            color: #d26e6e;
        }
    }
}

.no-y-margin {
    margin: 4px 0 !important;
}

svg {
    &.check-full {
        path {
            stroke: #00b1b7;
        }
    }
    &.check-not-full {
        path {
            stroke: #e28800;
        }
    }
    &.check-empty {
        path {
            stroke: #868686;
        }
    }
}

.absolute-center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    &.bottom {
        bottom: 32px;
    }
    &.top {
        top: 32px;
    }
}

.position-relative {
    position: relative;
}

.hover-pointer {
    &:hover {
        cursor: pointer;
    }
}

.rounded-bg-grey {
    background: #f4f5f7;
    border-radius: 20px;
    padding: 10px 14px;
    color: #868686;
}

.rounded-bg-double-grey {
    background: #e3e6ea;
    border-radius: 20px;
    padding: 10px 14px;
    color: #878787;
    &.blue-grey {
        background-color: #e8ecf2;
    }
}

.text-grey {
    color: #878787;
    font-weight: 600;
    font-size: 14px;
}

.gap-40 {
    gap: 40px;
}

.gap-10 {
    gap: 10px;
}

.w-300 {
    width: 300px;
}

.border-bottom-rounded {
    width: 50px;
    border-bottom: 1px solid #868686;
    border-radius: 4px;
    text-align: center;
    padding-bottom: 10px;
}

.no-white-space {
    white-space: nowrap;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}
