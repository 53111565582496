.search-input {
  input {
    width: 240px;
    height: 40px;
    border: 1px solid #8b8b8b;
    border-radius: 12px;
    font-size: 16px;
    outline: none;
    padding: 8px 16px 8px 16px;
    &::placeholder {
      color: #8b8b8b;
      font-size: 14px;
    }
  }
}
