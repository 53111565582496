.validation-container {
    max-height: 73vh;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 16px 16px 16px 16px;
    overflow-y: auto;
    overflow-x: hidden;
    .accordion-validation-panel {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 4px;
        margin-top: 8px;
    }
    .accordion-validation-item {
        background-color: #e8e8e8;
        border-radius: 20px;
        padding: 14px 16px 14px 16px;
        margin-right: 30px;
        margin-left: 30px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .validation-line {
        margin-bottom: 20px;
        margin-top: 20px;
        display: inline-block;
        background-color: #f4f5f7;
        height: 2px;
        width: 100%;
    }
    .add-validation-button-container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .validation-actions {
        color: #868686;
        display: flex;
        gap: 16px;
    }
    .validation-flow-title {
        color: #868686;
        font-size: 18px;
        text-transform: uppercase;
        margin-right: 6px;
    }
}
.handouts-wrapper {
    display: flex;
    gap: 20px;
    margin: 16px;
    flex-direction: column;
}
.add-handout-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.handout-item {
    width: 50%;
    display: flex;
    gap: 10px;
    align-items: center;
}
.block-with-cliploader {
    display: flex;
    justify-content: center;
    margin: 16px;
}
.add-validation-user-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: 20px;
}

.validation-process-container {
    max-height: 85vh;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 16px 16px 0px 16px;
    overflow-y: auto;
    overflow-x: hidden;

    & .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        .back-button {
            display: flex;
            align-items: center;
            gap: 20px;
        }
        .select-components {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
            .first-select {
                .css-1im77uy-control {
                    width: 160px;
                }
                .css-1hpbggg-control {
                    width: 160px;
                }
                .css-lkh0o5-menu {
                    width: 160px;
                }
                & .select-wrapper {
                    gap: 0;
                    width: 160px;
                }
                & .label {
                    margin-top: 10px;
                    margin-bottom: 4px;
                }
            }
            .select-wrapper {
                gap: 0;
                .label {
                    margin-top: 10px;
                    margin-bottom: 4px;
                }
            }
        }

        & .back-icon {
            height: 20px;
            cursor: pointer;
            color: #00b1b7;
            transition: all 0.3s ease;
            position: relative;
            display: inline-block;

            & :hover {
                cursor: pointer;
                color: #868686;
            }
        }

        .title {
            font-size: 24px;
            font-weight: 600;
            color: #868686;
            margin-right: 20px;
        }
    }

    & .carousel-section {
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .swiper {
            margin-left: inherit;
            margin-right: 60px;
        }
        .swiper-wrapper {
            padding-bottom: 32px;
            padding-left: 60px;
            margin-right: 60px;

            .swiper-slide {
                background-color: #f4f5f7;
                border-radius: 20px;
                height: 310px;
                width: 310px !important;
                display: flex;
                justify-content: center;

                & .first-block {
                    width: 275px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    & .edit-role-block {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        height: 50%;
                        gap: 16px;
                        & .validation-title {
                            color: #868686;
                            font-weight: 600;
                            font-size: 24px;
                        }
                    }
                    & .roles-block {
                        height: 40%;
                        margin: 0px 16px 16px 16px;
                        border-radius: 8px;
                        border: 1px solid #868686;
                        overflow-y: auto;
                        & .loader-container {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            margin-left: 45%;
                            margin-top: 40px;
                        }
                        & .role-checkbox-container {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            margin-left: 8px;
                        }
                    }
                    & .roles-block2 {
                        height: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        gap: 8px;
                        width: 85%;
                        overflow: auto;
                        margin-left: 30px;
                        align-content: flex-start;
                        & .role-badge {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 60px;
                            color: #555555;
                            height: 40px;
                            border-radius: 50px;
                            border: 1px solid rgb(193, 193, 199);
                        }
                    }
                    & .validation-block {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        height: 50%;
                        gap: 16px;
                        & .validation-title {
                            color: #868686;
                            font-weight: 600;
                            font-size: 24px;
                        }
                    }
                }

                & .second-block {
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    align-items: center;
                    padding: 0;
                    margin: 0;
                    .button-add-block {
                        background-color: #00b1b7;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        border-radius: 0px 20px 0px 0px;
                        width: 40px;
                        transition: all 0.3s ease;

                        &:hover {
                            cursor: pointer;
                            background-color: #1ed7dd;
                        }
                        .add-icon {
                            color: #ffffff;
                            :hover {
                                cursor: pointer;
                            }
                        }
                    }
                    .button-remove-block {
                        background-color: #d26e6e;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        border-radius: 0px 0px 20px 0px;
                        width: 40px;
                        transition: all 0.3s ease;
                        color: #ffffff;
                        &:hover {
                            cursor: pointer;
                            background-color: #db2b2b;
                        }
                    }
                    .button-remove-block-disabled {
                        background-color: #d26e6e;
                        height: 100%;
                        opacity: 40%;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        border-radius: 0px 0px 20px 0px;
                        width: 40px;
                        svg {
                            color: white;
                        }
                        &:hover {
                            cursor: not-allowed;
                        }
                    }
                    .button-add-block-disabled {
                        background-color: #00b1b7;
                        opacity: 40%;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        border-radius: 0px 20px 0px 0px;
                        border-bottom: 1px solid #868a9d;
                        width: 40px;
                        svg {
                            color: white;
                        }
                        &:hover {
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }

        .swiper-button-prev {
            margin-left: 16px;
            margin-right: 16px;
            & ::after {
                color: red;
            }
        }
        .swiper-button-prev:hover {
            color: #00b1b7;
        }

        .swiper-button-next:hover {
            color: #00b1b7;
        }
        .swiper-scrollbar-drag {
            background-color: #00b1b7;
        }
    }

    & .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        border-top: 2px solid #e8e9ed;
        padding: 16px 0;
        margin: 16px 0;
    }
}
