.drone-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    .title {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 26px;
        color: #868686;
        font-weight: 600;
    }
    .header-icon {
        path {
            fill: #868686;
        }
    }
    .header-datepicker {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #868686;
    }
}

.drone-table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 20px;
}

.drone-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    border-top: 2px solid #e8e9ed;
    padding: 16px 0;
    margin: 16px 0;
}