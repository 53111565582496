.roles-container {
  .add-role {
    position: fixed;
    bottom: 30px;
    right: 60px;
  }
  .roles {
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    padding: 14px 4px 4px 14px;
    border-radius: 20px;
    max-height: 73vh;
    overflow-y: auto;

    .role {
      display: flex;
      justify-content: space-between;
      width: 99%;
      align-items: center;
      border-radius: 20px;
      height: 68px;
      margin-bottom: 8px;
      hr {
        width: 100%;
        color: "#DFDFDF";
        border-top: 2px solid #dfdfdf;
      }

      td:first-child {
        max-width: 100px;
        .role-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          width: 60px;
          height: 40px;
          background-color: #a700e21a;
          color: #000000;
        }
      }

      td:last-child {
        justify-content: flex-end;
        max-width: 90px;
        .icons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;
          margin-right: 16px;
          .delete-icon {
            cursor: pointer;
            :hover {
              fill: #00b1b7;
            }
          }
          .edit-icon {
            cursor: pointer;
            :hover {
              fill: #00b1b7;
            }
          }
        }
      }
    }
  }
}
