.settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    .title {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 26px;
        color: #868686;
        font-weight: 600;
    }
    .header-icon {
        path {
            fill: #868686;
        }
    }
}

.tokens-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    height: 70vh;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 1);
    overflow-y: scroll;

    .date-pickers-section {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        padding: 20px 0px 20px 0px;
        background-color: #f4f5f7;
        border-radius: 20px;

        .date-picker-from {
            display: flex;
            align-items: center;
            gap: 20px;
            padding-left: 20px;
        }
        .date-picker-to {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
    .inputs-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        .bottom-inputs {
            display: flex;
            gap: 16px;
            margin-bottom: 16px;
        }
    }

    .settings-section {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .section-title {
            font-size: 20px;
            color: #868686;
            font-weight: 600;
        }
        .section-content {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }
    }

    .buttons-group {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
        &.py-10 {
            padding: 10px 0;
        }
    }
}

.buttons-group-tokens {
    position: fixed;
    bottom: 30px;
    right: 60px;
    &.py-10 {
        padding: 10px 0;
    }
}

.imsma-rights-container {
    background-color: rgba(244, 245, 247, 1);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 18px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;

    .inputs-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .top-inputs {
            display: flex;
            gap: 16px;
        }
    }
    .button-section {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}
.imsma-rights-container-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.settings-details {
    display: flex;
    gap: 30px;
    flex-direction: column;
    .settings-details-container {
        background-color: #ffffff;
        border-radius: 20px;
    }
    .settings-details-line {
        height: 2px;
        background-color: #f4f5f7;
        border: none;
        width: 97%;
        margin: 0 auto;
    }
}
