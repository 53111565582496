button {
  display: inline-flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background: #00b1b7;
  color: #f0f0f0;
  border: 1px solid #00b1b7;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  transition: ease-out 0.3s;
  &:hover,
  &:focus {
    border: 1px solid #00b1b7;
    cursor: pointer;
    color: #00b1b7;
    background-color: transparent;
  }
  &:active {
    background-color: #005d67;
    color: #f0f0f0;
  }
  &:disabled {
    background: #005d67;
    color: #8b8b8b;
    pointer-events: none;
  }
  &.danger {
    background-color: #d26e6e;
    border: 1px solid #d26e6e;
    &:hover,
    &:focus {
      border: 1px solid #d26e6e;
      cursor: pointer;
      color: #d26e6e;
      background-color: transparent;
    }
    &:active {
      background-color: #b45353;
      color: #f0f0f0;
      svg {
        path {
          fill: #f0f0f0;
        }
      }
    }
    &:disabled {
      background: #b45353;
      color: #8b8b8b;
      pointer-events: none;
    }
    &.outline {
      background: transparent;
      color: #b45353;
      &:hover,
      &:focus,
      &:active {
        background-color: #b45353;
        color: #f0f0f0;
      }
    }
  }
  &.warning {
    background-color: #e28800;
    border: 1px solid #e28800;
    &:hover,
    &:focus {
      border: 1px solid #e28800;
      cursor: pointer;
      color: #e28800;
      background-color: transparent;
    }
    &:active {
      background-color: #e28800;
      color: #f0f0f0;
    }
    &:disabled {
      background: #e28800;
      color: #8b8b8b;
      pointer-events: none;
    }
    &.outline {
      background: transparent;
      color: #e28800;
      &:hover,
      &:focus,
      &:active {
        background-color: #e28800;
        color: #f0f0f0;
      }
    }
  }
  &.green {
    background-color: #00bc13;
    border: 1px solid #00bc13;
    &:hover,
    &:focus {
      border: 1px solid #00bc13;
      cursor: pointer;
      color: #00bc13;
      background-color: transparent;
    }
    &:active {
      background-color: #00bc13;
      color: #f0f0f0;
    }
    &:disabled {
      background: #00bc13;
      color: #8b8b8b;
      pointer-events: none;
    }
    &.outline {
      background: transparent;
      color: #00bc13;
      &:hover,
      &:focus,
      &:active {
        background-color: #00bc13;
        color: #f0f0f0;
      }
    }
  }
  &.disabled-add-edit-role {
    background: #e28800;
    color: #ffffff;
    cursor: not-allowed;
    border: 1px solid #e28800;
    opacity: 40%;
  }
  &.disabled-save {
    background: #00b1b7;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 40%;
  }
  &.outline {
    background: transparent;
    color: #00b1b7;
    &:hover,
    &:focus,
    &:active {
      background-color: #00b1b7;
      color: #f0f0f0;
    }
  }
}

.btn-inside {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    filter: brightness(2.5);
  }
  &:hover {
    img {
      filter: none;
    }
  }
}

.buttons-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  &.py-10 {
    padding: 10px 0;
  }
}
.buttons-group-end {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  &.py-10 {
    padding: 10px 0;
  }
}
.buttons-group-user-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.form-buttons {
  position: fixed;
  bottom: 0;
  background: white;
  padding-top: 16px;
  width: calc(100% - 328px);
  z-index: 6;
}

.form-buttons-end {
  position: fixed;
  bottom: 0;
  background: white;
  padding-top: 16px;
  width: calc(100% - 360px);
}

.btn-inside-without-gap {
  span {
    display: none;
  }
}

.evidence-icons {
  display: flex;
  width: 90px;
  flex-wrap: wrap;
  justify-content: center;
  flex: 0 0 55px;
  align-items: center;
  gap: 5px;
}

.menu-btn {
  border-radius: 12px;
  text-align: center;
  align-self: center;
}
.user-btn {
  background-color: inherit;
  width: 20px;
  height: 20px;
  &:hover,
  &:focus {
    border: none;
    cursor: pointer;
    color: #00b1b7;
    background-color: transparent;
    svg {
      path {
        fill: #00b1b7;
      }
    }
  }
}

.filter-btn {
  height: 41px;
  background-color: transparent;
  border-radius: 8px;
  color: #868695;
  border: 1px solid #868695;

  &:hover {
    background-color: transparent;
    border: 1px solid #00b1b7;
    color: #00b1b7;
  }

  &:active {
    cursor: pointer;
    color: #f0f0ec;
    background-color: #00b1b7;
  }
  &:focus {
    cursor: pointer;
    color: #f0f0ec;
    background-color: #00b1b7;
  }
}

.add-access-btn {
  background-color: #00b1b7;
  color: #f0f0f0;
  border: 1px solid #00b1b7;
  border-radius: 20px;
  padding: 8px 16px 8px 24px;
  font-size: 14px;
  gap: 8px;
  font-family: "Montserrat-Medium";
  font-weight: 600;
  transition: ease-out 0.3s;
  &:hover,
  &:focus {
    border: 1px solid #00b1b7;
    cursor: pointer;
    color: #00b1b7;
    background-color: transparent;
  }
  &:active {
    background-color: #005d67;
    color: #f0f0f0;
  }
}
