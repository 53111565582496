.react-tabs {
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    flex-direction: row;
    gap: 0px;
    border: 0;
    justify-content: flex-end;

    li {
      color: #00b1b7;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #8b8b8b;
      padding-bottom: 15px;
      padding-right: 20px;
      padding-left: 20px;
      &.react-tabs__tab--selected {
        &::after {
          content: '';
          position: absolute;
          left: calc(50% - 15px);
          bottom: 0px;
          width: 30px;
          height: 6px;
          border-radius: 20px 20px 0px 0px;
          background: #00b1b7;
        }
      }
    }
  }
}

ul.tabs-with-circle {
  display: flex;
  flex-direction: row;
  gap: 100px;
  li {
    position: relative;
    &::after {
      //write css for line between li elements
      content: "";
      position: absolute;
      top: 50%;
      left: 100%; // Positioned after the li
      transform: translateY(-50%);
      width: 85px; // Width of the line
      height: 2px; // Thickness of the line
      background-color: #D9D9D9;
    }
    &:last-child::after {
      display: none; // Remove the line after the last li
    }
    a.tab-with-circle {
      color: #868686;
      margin-left: 22px;
      font-size: 22px;
      font-weight: 600;
    }
    .circle {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #D9D9D9;
      &.active {
        background: #F5B609;
      }
      &.completed {
        background: #00D57C;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -65%) rotate(300deg);
          width: 14px;
          height: 7px;
          border: solid white;
          border-width: 0 0 3px 3px;
        }
      }
    }
    &:hover {
      .circle{
        background: #F5B609;
      }
      a.tab-with-circle {
        color:#555555;
      }
    }
  }
}