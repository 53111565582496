.auth-main {
    min-height: 100vh;
    margin: 0 auto;
    background-image: linear-gradient(
            180deg,
            rgba(0, 49, 84, 0.6) 0%,
            rgba(0, 40, 68, 0.48) 19.39%,
            rgba(0, 49, 84, 0.12) 43.41%,
            rgba(0, 49, 84, 0.12) 63.54%,
            rgba(0, 49, 84, 0.6) 100%
        ),
        url("../../assets/images/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #002c2e;
    display: flex;
}

.auth-filter {
    backdrop-filter: blur(3px);
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-wrapper {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0 32px;
}

.login-form {
    display: flex;
    width: 340px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.big-text {
    font-size: 48px;
    font-weight: 600;
    color: #f0f0f0;
    text-align: end;
}

.error-text {
    color: #D26E6E !important;
}