.tcp-form,
.daily-and-weekly,
.daily-form {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 20px;
  .form-container {
    padding: 24px 0px;
    border-bottom: 2px solid #f4f5f7;
  }
  .form-header {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #868686;
    font-weight: 600;
    font-size: 21px;
    span {
      width: 200px;
    }
  }
  .form-footer {
    display: flex;
    align-items: right;
    justify-content: flex-end;
    padding: 12px 0px;
    gap: 8px;
  }
  .map-container {
    border: 1px solid #b8b8b8;
    border-radius: 20px;
  }
  .react-tabs ul {
    justify-content: flex-start;
  }
}

.gis-links {
  color: #868686;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .delete-icon {
    margin-top: 12px;
  }
}

.file-links,
.gis-links {
  display: flex;
  gap: 10px;
  // border-bottom: 1px solid #d8d8d8;
  padding: 14px 0;
  width: 60%;
  &.gis-block-links {
    width: 100%;
    border-bottom: 0;
  }
  &:last-child {
    border: 0;
    margin-bottom: 16px;
    border-bottom: none;
  }
  .file-name {
    display: flex;
    flex-direction: column;
    width: 50%;
    span {
      &:first-child {
        color: #868686;
        font-size: 14px;
        font-weight: 600;
      }
      &:last-child {
        color: #868686;
        font-size: 12px;
        font-weight: 500;
        height: 40px;
        display: flex;
        align-items: center;
      }
      a {
        color: #00b1b7;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &.error {
    .file-name {
      span {
        &:first-child,
        &:last-child {
          color: #d26e6e;
        }
      }
    }
    .file-link {
      label {
        color: #d26e6e;
      }
      input {
        border: 1px solid #d26e6e;
        &::placeholder {
          color: #d26e6e;
        }
      }
    }
  }
}

.title-with-line {
  display: flex;
  gap: 10px;
  align-items: center;
  .title {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
    &.error {
      span {
        &:first-child,
        &:last-child {
          color: #d26e6e;
        }
      }
    }
    span {
      &:first-child {
        color: #1e1e1e;
        font-size: 18px;
        font-weight: 600;
      }
      &:last-child {
        color: #868686;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.column-350 {
  .flex-column-group {
    width: 350px;
  }
}

.file-links {
  .one-link {
    border-bottom: 1px solid #d8d8d8;
    width: 100%;
    &:last-child {
      border: 0;
    }
  }
}

.gis-header {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #1e1e1e;
  font-weight: 600;
  font-size: 18px;
  span {
    width: 200px;
  }
  svg {
    color: #00b1b7;
    width: 35px;
    height: 35px;
  }
}

.eo-found-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  .category {
    display: flex;
    width: 300px;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #f4f5f7;
    color: #868686;
    font-size: 14px;
    font-weight: 500;
    .input-wrapper {
      width: auto;
    }
  }
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  .title {
    color: #555555;
    font-size: 14px;
    font-weight: 600;
  }
  .categories {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    .category {
      display: flex;
      gap: 5px;
      align-items: center;
      span {
        &:first-child {
          color: #868686;
          font-size: 14px;
          font-weight: 500;
          width: 200px;
        }
        &:last-child {
          color: #868686;
          font-size: 12px;
          font-weight: 500;
          width: 100px;
        }
      }
    }
  }
}
.hand-over-switcher,
.photos-switcher {
  padding: 8px 0px 8px 8px;
}

.hand-over-block,
.eo-photos-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
}

.unit-block {
  width: 60px;
}

.select-unit {
  position: absolute;
  top: 60px;
  border-radius: 4px;
  background-color: #f3f3f3;
  border: 1px solid #b8b8b8;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;

  span {
    padding: 8px 16px;
    width: 30px;
    text-align: center;
    &:not(:last-child) {
      border-bottom: 1px solid #b8b8b8;
    }
    &:hover {
      cursor: pointer;
      background-color: #e8e8e8;
      color: #00b1b7;
    }
  }
}

.arrow-down {
  &.open {
    transform: rotate(180deg);
  }
  &:hover {
    cursor: pointer;
    color: #00b1b7;
  }
}

.color-item {
  width: 15px;
  height: 15px;
}

.mapboxgl-popup-content {
  background-color: #e3e3e3;
  border-radius: 11px;
  padding: 8px;
  max-height: 200px;
  overflow: auto;
}

.hover-progress-map-popup {
  .mapboxgl-popup-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #868686;
    .date {
      font-weight: 600;
    }
  }
  .mapboxgl-popup-tip {
    display: none;
  }
}
